import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, CardStackPlus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import moment from "moment";

const OrdersPage: React.FC = () => {
  const navigate = useNavigate();

  // ダミーデータ
  const dummyCardHistory = [
    {
      id: 1,
      card_name: "ドラゴンカード",
      acquired_at: "2024-12-10T12:00:00Z",
      category: "ファンタジー",
      rarity: "SSR",
    },
    {
      id: 2,
      card_name: "ウォーリアカード",
      acquired_at: "2024-12-09T15:30:00Z",
      category: "アクション",
      rarity: "SR",
    },
    {
      id: 3,
      card_name: "マジシャンカード",
      acquired_at: "2024-12-08T10:15:00Z",
      category: "魔法",
      rarity: "R",
    },
  ];

  const [cardHistory, setCardHistory] = useState(dummyCardHistory);

  return (
    <div className="p-4">
      <div className="mx-auto grid flex-1 auto-rows-max gap-4">
        {/* ヘッダー */}
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">戻る</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            カード獲得履歴
          </h1>
        </div>

        {/* タブと履歴表示 */}
        <Tabs defaultValue="all">
          <TabsList className="grid grid-cols-1">
            <TabsTrigger value="all">獲得履歴</TabsTrigger>
          </TabsList>

          <TabsContent value="all">
            {cardHistory.length > 0 ? (
              <div className="grid gap-2 p-2">
                {cardHistory.map((history) => (
                  <Card key={history.id}>
                    <CardHeader>
                      <CardTitle className="text-sm font-medium">
                        {history.card_name}
                      </CardTitle>
                    </CardHeader>
                    <CardContent>
                      <time className="text-xs text-gray-500 block">
                        {moment(history.acquired_at).format("YYYY/MM/DD HH:mm")}
                      </time>
                      <p>
                        カテゴリ: <span className="font-semibold">{history.category}</span>
                      </p>
                      <p>
                        レアリティ:{" "}
                        <span className="font-semibold text-yellow-500">
                          {history.rarity}
                        </span>
                      </p>
                    </CardContent>
                  </Card>
                ))}
              </div>
            ) : (
              <p>カード獲得履歴はまだありません</p>
            )}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default OrdersPage;
