import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const PointPage: React.FC = () => {
  // 所有ポイントの状態
  const [points, setPoints] = useState<number>(1000);

  // 支払い方法リスト
  const paymentMethods = ["クレジットカード", "PayPay", "LINE Pay", "楽天ペイ"];

  // チャージ額リスト
  const chargeAmounts = [1000, 2000, 5000, 10000];

  // 選択された支払い方法の状態
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    paymentMethods[0]
  );

  return (
    <div style={{ padding: "20px" }}>
      {/* 所有ポイントカード */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>所有ポイント</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-xl font-bold">{points} ポイント</p>
        </CardContent>
        <CardFooter>
          <Button variant="outline" disabled>
            現在のポイント
          </Button>
        </CardFooter>
      </Card>

      {/* 支払い方法セレクター */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>支払い方法を選択</CardTitle>
        </CardHeader>
        <CardContent>
          <select
            value={selectedPaymentMethod}
            onChange={(e) => setSelectedPaymentMethod(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            {paymentMethods.map((method, index) => (
              <option key={index} value={method}>
                {method}
              </option>
            ))}
          </select>
        </CardContent>
        <CardFooter>
          <Button variant="secondary">{selectedPaymentMethod}</Button>
        </CardFooter>
      </Card>

      {/* チャージ額リスト */}
      <Card>
        <CardHeader>
          <CardTitle>チャージ額を選択</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-wrap gap-4">
            {chargeAmounts.map((amount, index) => (
              <Button
                key={index}
                variant="outline"
                onClick={() => {}}
              >
                {amount} 円
              </Button>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default PointPage;
