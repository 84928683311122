import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchSiteEvents, Event, fetchSiteDetail, Site, fetchSiteCategories, Category, fetchSiteTags, Tag } from "@/services/customerService";
import EventCard from "@/components/EventCard";

const SiteDetailPage: React.FC = () => {
  const { id: siteId } = useParams<{ id: string }>();
  const sortOptions = [
    { name: "おすすめ順", slug: "recommend" },
    { name: "新着順", slug: "new" },
    { name: "人気順", slug: "popular" },
    { name: "価格が安い順", slug: "lowPrice" },
    { name: "価格が高い順", slug: "highPrice" },
  ];
  const [categories, setCategories] = useState<Category[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>({
    id: 0,
    name: "ALL",
    slug: "all",
  });
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [siteDetails, setSiteDetails] = useState<Site | null>(null);

  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadSiteDetails = async () => {
      try {
        if (!siteId) return;
        const siteData = await fetchSiteDetail(parseInt(siteId, 10));
        setSiteDetails(siteData);
      } catch (err) {
        console.error(err);
        setError("店舗情報の取得に失敗しました");
      }
    };

    const loadData = async () => {
      try {
        if (!siteId) return;
        const [categoriesData, tagsData] = await Promise.all([
          fetchSiteCategories(parseInt(siteId, 10)),
          fetchSiteTags(parseInt(siteId, 10)),
        ]);
        setCategories([{ id: 0, name: "ALL", slug: "all" }, ...categoriesData]);
        setTags(tagsData);
      } catch (err) {
        console.error(err);
      }
    };

    loadSiteDetails();
    loadData();
  }, [siteId]);

  const loadEvents = async (categorySlug?: string, tagSlugs?: string[]) => {
    setLoading(true);
    setError(null);
    try {
      if (!siteId) return;

      const data = await fetchSiteEvents(
        parseInt(siteId, 10),
        categorySlug === "all" ? undefined : categorySlug,
        tagSlugs
      );
      setEvents(data);
    } catch (err: any) {
      setError(err.message || "イベントの取得に失敗しました");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEvents(selectedCategory?.slug, selectedTags.map((tag) => tag.slug));
  }, [selectedCategory, selectedTags, siteId]);

  const getAttachmentUrl = (event: Event, identifier: string) =>
    event?.attachments.find((attachment) => attachment.identifier === identifier)?.url;

  return (
    <main className="container mx-auto max-w-screen-lg px-4 py-8">
      {/* 店舗情報 */}
      <div className="relative h-32 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
        {siteDetails && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-center text-white">
              <h1 className="text-2xl font-bold">{siteDetails.name}</h1>
            </div>
          </div>
        )}
      </div>

      {/* カテゴリーとタグ */}
      <div className="mt-8 border-b-4 border-gray-300 border-solid overflow-x-auto whitespace-nowrap scrollbar-hide">
        <nav className="flex gap-6" aria-label="Tabs">
          {categories.map((category) => (
            <Link
              key={category.slug}
              to={`/${category.slug}`}
              className={
                `text-md font-bold px-1 pb-2 inline-block ${
                  selectedCategory?.slug === category.slug
                    ? "border-solid border-b-4 border-red-500 text-gray-600"
                    : "text-gray-400"
                }`
              }
              onClick={(e) => {
                e.preventDefault();
                setSelectedCategory(category);
              }}
            >
              {category.name}
            </Link>
          ))}
        </nav>
      </div>

      <div className="mt-4">
        <div className="flex justify-between items-center">
          <div>
            {tags.map((tag) => (
              <a
                key={tag.slug}
                href="#"
                className={`inline-block rounded-full p-[2px] mr-2 focus:outline-none focus:ring active:text-opacity-75 ${
                  selectedTags.find((selectedTag) => selectedTag.slug === tag.slug)
                    ? "bg-gradient-to-r from-yellow-500 via-red-500 to-pink-500 text-white"
                    : "bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 hover:text-white"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    selectedTags.find((selectedTag) => selectedTag.slug === tag.slug)
                  ) {
                    setSelectedTags(
                      selectedTags.filter((selectedTag) => selectedTag.slug !== tag.slug)
                    );
                  } else {
                    setSelectedTags([...selectedTags, tag]);
                  }
                }}
              >
                <span
                  className={`block rounded-full px-4 py-2 text-xs font-medium ${
                    selectedTags.find((selectedTag) => selectedTag.slug === tag.slug)
                      ? "bg-transparent"
                      : "bg-white hover:bg-transparent"
                  }`}
                >
                  {tag.name}
                </span>
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* イベント一覧 */}
      <div className="mt-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
          {events.map((event) => (
            <EventCard
              key={event.uid}
              siteId={siteId}
              uid={event.uid}
              name={event.name}
              description={event.description}
              imageUrl={getAttachmentUrl(event, "thumbnail")}
            />
          ))}
        </div>
      </div>
    </main>
  );
};

export default SiteDetailPage;
